<template>
  <div ref="container" class="app-container top-up-record">
    <baseTable ref="table" class="search-form non-expand-icon" :list="list" :checkAll="true">
      <template #menuLeft="{ size }">
        <el-button
          size="small"
          v-if="permission.exportBillBtn"
          type="primary"
          @click="exportBill"
          :loading="exportLoading"
          >账单导出</el-button
        >
        <el-button
          size="small"
          v-if="permission.exportBtn"
          type="primary"
          @click="exportBtn"
          :loading="exportBtnLoading"
          >导出</el-button
        >
      </template>
      <template #applyTimeSearchForm="{ form, prop }">
        <SaleDateTime :time.sync="form[prop]" @change="searchChange"></SaleDateTime>
      </template>
      <template #receiptFilePath="{ row, prop }">
        <defaultImg disabled size="large" :src="row.receiptFilePath" :preview="true"></defaultImg>
      </template>
      <template #menu="{ row }">
        <template v-if="row.approveStatus === APPROVE_STATUS_PENDING">
          <loadingBtn
            v-if="btnpermission.permit"
            type="text"
            class="menu-btn-item"
            @click="handleExtendDialog('resolve', row)"
            >通过</loadingBtn
          >
          <loadingBtn
            v-if="btnpermission.refuse"
            type="text"
            class="menu-btn-item"
            @click="handleExtendDialog('reject', row)"
            >拒绝</loadingBtn
          >
        </template>
      </template>
    </baseTable>
    <paymentPassBox
      :loading.sync="loading"
      :passwordVal.sync="passwordVal"
      :payVisible.sync="payVisible"
      @commitPassword="commitPassword"
    ></paymentPassBox>
  </div>
</template>

<script>
import paymentPassBox from '@/views/components/payDialog'
import SaleDateTime from '@/components/saleDateTime'
import defaultImg from '@/views/components/defaultImg'
import { list, permission } from './const'
import { updateRechargeApplyRecordPermit, updateRechargeApplyRecordRefuse } from '@/api/financial/topUpRecord'
import { APPROVE_STATUS_PENDING } from '@/utils/constant'
import { passwordSetted, rechargeRecordExport, backgroundManualAdjustment } from '@/api/balanceApi'
import { mapGetters } from 'vuex'
import baseTable from '@/components/base/baseTable/mixins/baseTable'
import getBaseTableData from '@/components/base/baseTable/mixins/getBaseTableDataMixin'
import saveAs from 'jszip/vendor/FileSaver.js'
import promise from '@/extend/mixins/dialog/promise'
export default {
  name: 'topUpRecord',
  mixins: [
    baseTable,
    getBaseTableData({
      dataAttrs: {
        postData: {},
        isCheckAllFromBtn: false,
        curTabItem: {}
      }
    })
  ],
  components: {
    defaultImg,
    SaleDateTime,
    paymentPassBox
  },
  data() {
    return {
      btnpermission: Object.freeze(permission),
      APPROVE_STATUS_PENDING,
      resetMergeData: {
        orderItems: [
          {
            column: 'apply_time',
            asc: false
          }
        ]
      },
      state: '',
      passwordVal: [],
      payVisible: false,
      list,
      loading: false,
      rowData: {},
      dialogRef: {},
      billList: [],
      exportLoading: false,
      exportBtnLoading: false
    }
  },
  computed: {
    ...mapGetters(['is_super'])
  },
  methods: {
    // 账单导出
    async exportBill() {
      this.billList = await this.$refs.table.getSelectionDataAllArr()
      if (this.billList.length === 0) return this.$message.warning('请选择账单')
      this.exportLoading = true
      const idList = this.billList.map(({ id }) => id)
      rechargeRecordExport({ idList })
        .then((res) => {
          // const fileName = type == 2 ? '按款式导出的发票详情数据' : '按订单导出的发票详情数据'
          const fileName = this.curTabItem.label
          saveAs(res, fileName)
          this.$message.success('导出成功')
        })
        .catch((e) => {
          console.log(e)
          this.$message.error('导出失败')
        })
        .finally(() => {
          this.exportLoading = false
        })
      // console.log('this.reviewdata',this.reviewData.id);
    },
    // 导出
    async exportBtn() {
      let list = await this.$refs.table.getSelectionDataAllArr()
      if (list.length === 0) return this.$message.warning('请选择账单')
      let idList = list.map(({ id }) => id)
      this.exportBtnLoading = true
      try {
        let tradeType
        if (this.curTabItem.value === 2) {
          tradeType = 5
        } else {
          tradeType = 10
        }
        let res = await backgroundManualAdjustment({
          tradeType,
          idList
        })
        if (res instanceof Blob) {
          let url = URL.createObjectURL(res)
          let aLink = document.createElement('a')
          aLink.href = url
          if (this.curTabItem.value === 2) {
            aLink.setAttribute('download', '后台手工调整')
          } else {
            aLink.setAttribute('download', '用户信用额度变更记录')
          }

          aLink.click()
          this.$message.success('操作成功')
        } else {
          this.$message.error('操作失败')
        }
      } catch {
      } finally {
        this.exportBtnLoading = false
      }
    },
    async commitPassword() {
      let api = updateRechargeApplyRecordPermit

      try {
        let data = {
          id: this.rowData.id
        }
        if (!this.is_super) {
          const approveConfirmPassword = this.processPaymentPassword()
          if (!approveConfirmPassword) {
            return
          }
          data = Object.assign(data, { approveConfirmPassword })
        }
        this.loading = true
        const { code, detail } = await api(data)
        if (!$SUC(code)) {
          this.payVisible = false
          this.dialogRef.dialogClose()
          this.init()
          this.$message.success('审核成功')
        }
      } finally {
        this.loading = false
      }
    },
    processPaymentPassword() {
      let pwd = ''
      this.passwordVal
        .filter((item) => {
          return item !== ''
        })
        .map((item) => {
          pwd += item
        })
      if (pwd.length !== 6) {
        this.$message.warning('请输入完整密码')
        return undefined
      } else {
        return pwd
      }
    },
    async handleExtendDialog(type, row) {
      let {
        curTabItem: {
          auditDialogOption: {
            [type]: { title, confirmText, component }
          }
        }
      } = this
      let data = [row]
      component.forEach((item) => {
        if (item.component === 'customTable') {
          Object.assign(item, {
            type,
            data
          })
        }
      })
      let api = type === 'resolve' ? updateRechargeApplyRecordPermit : updateRechargeApplyRecordRefuse
      await this.$dialog({
        title,
        titleIcon: 'zhuyi',
        confirmText,
        width: '840px',
        customClass: 'top-up-record-dialog',
        component,
        doFn: async (form, that) => {
          if (type == 'resolve') {
            this.dialogRef = that
            this.rowData = row
            try {
              if (!this.is_super) {
                const { code, detail } = await passwordSetted({})
                if (!$SUC(code)) {
                  // 如果当前登录用户没有设置交易密码，则提示跳转并return
                  if (detail == 0) {
                    this.$message.warning('当前登录用户未设置交易密码')
                  } else {
                    this.passwordVal = []
                    this.payVisible = true
                  }
                }
              } else {
                this.commitPassword()
              }
            } catch (error) {}
            return
          }
          return awaitResolve(
            api({
              id: row.id,
              approveOpinion: type === 'reject' ? form.approveOpinion : undefined
            })
          )
        },
        success: this.success
      })
      this.init()
    },

    sortChange({ prop, order }) {
      console.log(prop, order)
    }
  }
}
</script>

<style lang="scss">
.top-up-record {
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #edf4ff;
  }
}
.top-up-record-dialog {
  .svg-icon {
    color: #fa9841;
  }

  .el-dialog__body {
    padding: 30px 25px 15px;
  }
  .audit-dialog-title {
    height: 39px;
    line-height: 39px;
    padding-left: 14px;
    margin: -17px 0 17px;
    background: #ebf0f8;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #595961;
  }
  .crud-container {
    .menu-left {
      margin: 0;
    }
    .el-table {
      margin-bottom: 0;
    }
  }
  .form-container {
    margin-top: 28px;
    .avue-group__item,
    .el-form-item {
      margin-bottom: 0;
    }
    .el-form-item__label {
      padding-right: 17px;
      line-height: 18px;
      white-space: nowrap;
      color: #969799;
      &::before {
        content: '' !important;
      }
    }
    .el-form-item__content {
      line-height: normal;
    }
    .el-textarea__inner {
      border-radius: 2px;
    }
  }
}
</style>
